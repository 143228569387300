<template>
  <ion-page>
    <Header type="0" title="检查件入库"> </Header>
    <ion-content
      style="background: white"
      :scroll-y="true"
      id="ProjectMainProjectList"
    >
      <div class="root">
        <div class="item">
          <div class="left"><span class="required">*</span>零件编号</div>
          <div class="right">
            <div class="searchBox">
              <input
                @input="scan"
                placeholder="请扫描零件二维码"
                v-model="part.partCode"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 无数据  -->
      <div class="no_data" v-if="tableList.length == 0 && !islist">
        <img
          class="no_data_img no_data_top"
          src="@/assets/images/notDate/notSearch.png"
          alt
        />
        <p class="no_data_text">未查询到信息</p>
      </div>
      <div class="app-container" v-for="(item, idx) in tableList" :key="idx">
        <div
          class="item"
          style="border-bottom: 1px solid #e2e2e2; padding-bottom: 15px"
        >
          <div class="item-label">{{ item.partCode }}</div>
          <div class="title">{{ item.partNameCN }}</div>
          <div class="rate">{{ item.partVersion }}</div>
        </div>
        <div class="item">
          <div class="item-label">数量</div>
          <div class="item-content">{{ item.inNum }}</div>
        </div>
        <div
          class="item"
          style="border-bottom: 1px solid #e2e2e2; padding-bottom: 25px"
        >
          <div class="item-label">库位</div>
          <div class="item-content">
            <ion-text @click.stop="storeReview(item, idx)">
              <span class="addBorderRight">{{ item.localeCodes }}</span>
            </ion-text>
            <ion-icon
              @click.stop="addTaskHandler(item, idx)"
              class="icon"
              :icon="qrCode"
            ></ion-icon>
          </div>
        </div>
      </div>
      <CardLoading
        v-if="tableList.length <= 0 && islist"
        lodingType="1"
      ></CardLoading>
    </ion-content>
    <ion-footer>
      <!-- <ion-button expand="block" @click="testScan">测试</ion-button> -->
      <ion-button @click="commit" expand="block">提交</ion-button>
    </ion-footer>
    <StoreChoose
      :ids="stockLoacleIds"
      :stocks="stocks"
      @choose="choose"
      @transfer="transfer"
    ></StoreChoose>
    <scanner v-if="showScan" @closeScan="closeScan"></scanner>
  </ion-page>
</template>
<script>
import {
  IonLabel,
  IonInput,
  IonItem,
  IonCard,
  IonPage,
  IonContent,
  menuController,
  popoverController,
  modalController,
  alertController,
  ionAlert,
  loadingController,
} from "@ionic/vue";
import { qrCode } from "ionicons/icons";
import Header from "@/components/Header.vue";
import StoreChoose from "./storeChoose.vue";
import SearchBar from "@/components/SearchBar.vue";
import scanner from "@/components/cordova/qrScanner.vue";
import {
  getStorageItem,
  storagePublish,
} from "@/api/storeManagement/warehouseReceiving";
import { getStageCheck } from "@/api/storeManagement/checkPutinStorage";
import Utils from "@/utils/Utils.ts";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import storeReview from "./storeReview.vue";
export default {
  name: "checkPutinStorage",
  components: {
    qrCode,
    IonItem,
    IonInput,
    IonLabel,
    IonCard,
    IonPage,
    IonContent,
    menuController,
    popoverController,
    modalController,
    Header,
    SearchBar,
    StoreChoose,
    scanner,
    CardLoading,
    storeReview,
  },
  data() {
    return {
      qrCode,
      idx: 0,
      stockLoacleIds: "",
      stocks: "",
      tableList: [],
      bill: {},
      showScan: false,
      islist: false,
      part: {
        partCode: "",
      },
      parData: {},
    };
  },
  created() {
    // this.closeScan({ partCode: "1301010-43Q", partVersion: "B" });
  },
  methods: {
    transfer() {
      this.showScan = true;
    },
    test(tableList) {
      var _this = this;
      var options = {
        font: {
          size: 22,
          italic: true,
          align: "center",
        },
        header: {
          height: "6cm",
          label: {
            text: "\n\nDie Freuden",
            font: {
              bold: true,
              大小: 37,
              对齐: "center",
            },
          },
        },
        footer: {
          height: "4cm",
          label: {
            text: "Johann Wolfgang von Goethe, 1749-1832, deutscher Dichter, Naturforscher",
            字体: { align: "center" },
          },
        },
      };
      var str = "";
      if (tableList.length == 0) {
        _this.$router.go(-1);
        return false;
      }
      tableList.forEach((e, i) => {
        str += ` <tr>
        <td >${i + 1}</td>
        <td >${e.partCode}</td>
        <td >${e.partNameCN || ""}</td>
        <td >${e.trialNum || 0}</td>
        <td >${e.inNum || 0}</td>
        <td >${this.parData.buyer || ""}</td>
          <td >${e.localeCodes || ""}</td>
      </tr>`;
      });
      console.log(str);
      window.PrinterPlugin.print(
        `<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
</head>

<body>
  <div style="padding:1px;break-after: page;position:relative">
    <div style="${this.title().title}">
    <div style="${
      this.title().header
    }left: 40%; top: 5pt;font-size: 22pt; ">入库验收单</div>  
    <div style="${
      this.title().header
    }left: 60%; top: 40pt;font-size: 16pt; ">验收单号：${
          this.bill.code || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 20pt; top: 70pt;font-size: 16pt;">试制任务单：${
          this.bill.stageCode || ""
        }</div>  
    <div style="${
      this.title().header
    }left:20pt; top: 90pt;font-size: 16pt;">试制提单人：${
          this.bill.submitPerson || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 20pt; top: 110pt;font-size: 16pt;">提单人电话：${
          this.bill.submitPersonPhone || ""
        }</div>  
    <div style="${
      this.title().header
    }left:270pt; top: 70pt;font-size: 16pt;">采购申请号：${
          this.bill.purchaseCode || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 270pt; top: 90pt;font-size: 16pt;">项目费用号：${
          this.bill.projectCode || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 270pt; top: 110pt;font-size: 16pt;">供 应 商：${
          this.bill.suppliers || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 500pt; top: 70pt;font-size: 16pt;">验 收 人：${
          this.bill.stockLeader || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 500pt; top: 90pt;font-size: 16pt;">验收时间：${
          this.bill.inDate || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 20pt; top: 130pt;font-size: 16pt;">库房：${
          this.bill.HOUSENAME || ""
        }</div>  
   
  </div>
  <table width="1000px" style="${
    this.title().title
  }border-collapse:collapse;margin-top: 160pt;" border="2px solid black" >
      <tr>
        <td width="50pt">序号</td>
        <td width="154pt">零件号</td>
        <td width="303pt">零件名称</td>
        <td width="70pt">试制数量</td>
        <td width="70pt">入库数量</td>
        <td width="100pt">采购员</td>
        <td width="150pt">库房</td>
      </tr>
    ${str}
    </table>
    </div>
  
</body>
</html><!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Document</title>
</head>

<body>
  <div style="padding:1px;">
    <div style="${this.title().title}">
    <div style="${
      this.title().header
    }left: 40%; top: 5pt;font-size: 22pt; ">入库验收单</div>  
    <div style="${
      this.title().header
    }left: 60%; top: 40pt;font-size: 16pt; ">验收单号：${
          this.bill.code || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 20pt; top: 70pt;font-size: 16pt;">试制任务单：${
          this.bill.stageCode || ""
        }</div>  
    <div style="${
      this.title().header
    }left:20pt; top: 90pt;font-size: 16pt;">试制提单人：${
          this.bill.submitPerson || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 20pt; top: 110pt;font-size: 16pt;">提单人电话：${
          this.bill.submitPersonPhone || ""
        }</div>  
    <div style="${
      this.title().header
    }left:270pt; top: 70pt;font-size: 16pt;">采购申请号：${
          this.bill.purchaseCode || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 270pt; top: 90pt;font-size: 16pt;">项目费用号：${
          this.bill.projectCode || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 270pt; top: 110pt;font-size: 16pt;">供 应 商：${
          this.bill.suppliers || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 500pt; top: 70pt;font-size: 16pt;">验 收 人：${
          this.bill.stockLeader || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 500pt; top: 90pt;font-size: 16pt;">验收时间：${
          this.bill.inDate || ""
        }</div>  
    <div style="${
      this.title().header
    }left: 20pt; top: 130pt;font-size: 16pt;">库房：${
          this.bill.HOUSENAME || ""
        }</div>   
   
  </div>
  <table width="1000px" style="${
    this.title().title
  }border-collapse:collapse;margin-top: 160pt;" border="2px solid black" >
      <tr>
        <td width="50pt">序号</td>
        <td width="154pt">零件号</td>
        <td width="303pt">零件名称</td>
        <td width="70pt">试制数量</td>
        <td width="70pt">入库数量</td>
        <td width="100pt">采购员</td>
        <td width="150pt">库房</td>
      </tr>
    ${str}
    </table>
    </div>
  
</body>
</html>`,
        options,
        function (e) {
          _this.$router.go(-1);
          console.log("success", e);
        },
        function (e) {
          console.log("error", e);
        }
      );
    },
    title() {
      return {
        title: "width: 800pt; position: relative;",
        name: "margin: 0px;padding: 0px;overflow: hidden",
        header:
          "position: absolute; padding: 2pt; text-align: left; border: 0pt solid black; font-family: Microsoft YaHei; word-break: break-all; display: flex; align-items: center;",
        tableTd:
          "padding: 2pt; font-size: 14pt; text-align: left; border: 1pt solid black; font-family: Microsoft YaHei; word-break: break-all; display: flex; align-items: center; left: 14pt; top: 0pt; height: 20pt; width: 50pt; text-overflow: ellipsis; overflow: hidden;",
      };
    },
    getList(content) {
      this.islist = true;
      getStageCheck({
        partCode: content.partCode,
        partVersion: content.partVersion,
        stageCode: content.stageCode,
      }).then((res) => {
        this.islist = false;

        if (!res.data.isError && res.data.data.isSuccess) {
          this.bill = res.data.data.bill;
          this.tableList = res.data.data.resultData;
        } else {
          Utils.presentToastTopWarning(
            res.data.message || res.data.data.message,
            "danger"
          );
        }
      });
    },
    addTaskHandler(val, idx) {
      this.idx = idx;
      console.log(val.stockLoacleIds);
    if (val.stockLoacleIds&&val.stockLoacleIds.indexOf("(") > -1) {
        var str = val.stockLoacleIds
          ? val.stockLoacleIds.substr(1, val.stockLoacleIds.length - 2)
          : "";

        this.stockLoacleIds = str.split(")(").join(",");
      } else {
        this.stockLoacleIds = val.stockLoacleIds||'';
      }

      this.stocks = val.localeCodes || "";
      menuController.enable(true, "StoreChoose");
      menuController.open("StoreChoose");
    },
    getTaskCode() {
      menuController.enable(true, "searchTask");
      menuController.open("searchTask");
    },
    // 选择库位
    choose(val, val1) {
      this.tableList[this.idx].stockLoacleIds = val;
      this.tableList[this.idx].localeCodes = val1;
      this.stockLoacleIds = val;
    },
    async commit() {
      const _this = this;
      if (_this.tableList.length == 0) {
        Utils.presentToastTopWarning("无可入库零件", "danger");
        return false;
      }
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "提示",
        message: "是否提交入库单",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: async () => {
              _this.tableList.forEach((e) => {
                e.isCheck = e.isCheck ? 1 : 0;
              });
              const loading = await loadingController.create({
                cssClass: "my-custom-class",
                message: "请稍等...",
                duration: 50000,
              });

              await loading.present();

              storagePublish({
                billId: _this.bill.id,
                datas: _this.tableList,
              }).then((res) => {
                loading.dismiss();
                _this.bill = res.data.data.bill;
                _this.parData = res.data.data.trialOrderBill;
                _this.test(res.data.data.item);
                if (!res.data.isError && res.data.data.isSuccess) {
                  Utils.presentToastTopWarning("提交成功", "success");
                } else {
                  Utils.presentToastTopWarning(
                    res.data.message || res.data.data.message,
                    "warning"
                  );
                }
              });
            },
          },
        ],
        ionAlertDidPresent: (e) => {
          console.log(e);
        },
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
    testScan() {
      this.part.partCode =
        '"partVersion":"B","partCode":"2701010-2024","billId":"25563","stageCode":"2020SZ04799';
    },
    scan(val) {
      var _this = this;
      this.$nextTick(() => {
        var scanVal = _this.part.partCode.trim();
        if (scanVal.indexOf('"') > -1) {
          _this.part.partCode = "";
          if (scanVal.indexOf("partCode") > -1) {
            var contents = JSON.parse(
              "{" + scanVal.slice(scanVal.indexOf('"')) + "}"
            );
            _this.part.partCode = contents.partCode;
            _this.getList(contents);
          } else {
            Utils.presentToastTopWarning("请正确扫描二维码", "danger");
            _this.part.partCode = "";
          }
        }
      });

      this.$forceUpdate();
    },
    closeScan(contents) {
      this.showScan = false;
      if (contents.partCode && contents.partVersion) {
        this.getList(contents);
      } else if (contents.id && contents.localeCode) {
        if (this.stockLoacleIds.indexOf(contents.id) == -1) {
          var list = this.stockLoacleIds ? this.stockLoacleIds.split(",") : [];
          list.push(contents.id);
          var list1 = this.tableList[this.idx].localeCodes
            ? this.tableList[this.idx].localeCodes.split(",")
            : [];
          list1.push(contents.localeCode);
          this.choose(list.join(","), list1.join(","));
        } else {
          Utils.presentToastTopWarning("此零件已上架该库位", "danger");
        }
      } else {
        Utils.presentToastTopWarning("请扫描正确二维码", "danger");
      }
    },
    async storeReview(item, idx) {
      var _this = this;
      const modal = await modalController.create({
        component: storeReview,
        cssClass: "my-custom-class",
        componentProps: {
          localeCodes: item.localeCodes,
          stockLoacleIds: item.stockLoacleIds,
        },
      });
      modal.present();
      var data = await modal.onDidDismiss();
      if (!data.data) {
        return false;
      }
      var list = data.data.list;
      var list1 = [];
      var list2 = [];
      list.forEach((e) => {
        list1.push(e.id);
        list2.push(e.str);
      });
      this.tableList[idx].stockLoacleIds = list1.join(",");
      this.tableList[idx].localeCodes = list2.join(",");
    },
  },
};
</script>
<style lang="scss" scoped>
ion-button {
  --background: #00529c;
}
.root {
  width: calc(100% #{px2rem(58)});
  height: auto;
  margin: px2rem(20) auto;
  background: #ffffff;
  border-radius: $border-radius;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: px2rem(20) px2rem(45);
  font-size: #{$default-font-size};
}
#ProjectMainProjectList {
  text-align: center;
  .content {
    text-align: left;
    margin: 0 auto;
    padding-top: px2rem(76);
    height: px2rem(900);
    .top-box {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding-left: px2rem(34);
      padding-right: px2rem(47);
      border-left: px2rem(16) solid #1782e3;
      width: 100%;
      height: px2rem(112);
      .articl-left {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        p:nth-child(1) {
          font-size: px2rem(34);
          font-weight: 400;
          color: #1b2749;
        }
        p:nth-child(2) {
          font-size: px2rem(22);
          font-weight: 400;
          color: #7f8592;
        }
      }
      .articl-right {
        display: flex;
        justify-content: center;
        display: inline-block;
        width: px2rem(26);
        p {
          display: inline-block;
          writing-mode: vertical-rl;
          font-size: px2rem(22);
          font-weight: 400;
          text-align: right;
          color: #dfe0e5;
        }
      }
    }
    .center-box {
      margin: 0 auto;
      box-sizing: border-box;
      padding: px2rem(46) 0 px2rem(66) 0;
      width: calc(100% - #{px2rem(85)});
      height: auto;
      li {
        margin-bottom: px2rem(34);
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: px2rem(24);
          font-weight: 400;
          color: #1b2749;
        }
        .addDisplayFlex {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .addBorderRight {
          display: inline-block;
          padding-right: px2rem(20);
          margin-right: px2rem(20);
          height: px2rem(36);
          border-right: 2px solid rgba(151, 151, 151, 0.3);
        }
      }
      .last-li {
        margin-top: px2rem(46);
        padding-top: px2rem(36);
        border-top: 1px solid #dfe0e5;
      }
    }
  }
  .ion-card {
    margin: 0 auto;
    margin-top: px2rem(20);
    width: calc(100% - #{px2rem(102)});
  }
  .submit {
    margin: 0 auto;
    margin-top: px2rem(76);
    width: calc(100% - #{px2rem(102)});
    --background: #1782e3;
  }
}
.app-container {
  width: calc(100% - 2.5rem);
  height: auto;
  background: #ffffff;
  border-radius: 0.875rem;
  margin: 1.25rem auto;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: 1.25rem 2.8125rem;
  font-size: 1.625rem;
  div {
    padding: 5px 0;
  }
  .title {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
  }
  .rate {
    font-size: 15px;
    font-weight: 500;
    color: #1782e3;
  }
  ion-item {
    --background: #ffffff;
    --padding-start: 0;
  }
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-content {
      .addBorderRight {
        display: inline-block;
        padding-right: px2rem(20);
        margin-right: px2rem(20);
        height: px2rem(36);
        border-right: 2px solid rgba(151, 151, 151, 0.3);
        width: 95px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .item-label {
      width: 40%;
      font-size: 10px;
      opacity: 0.5;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;

      text-align: left;
    }
  }
}
.checkboxText {
  vertical-align: middle;
}
input {
  vertical-align: middle;
  border: 0;
  outline: none;
  text-align: right;
}
</style>